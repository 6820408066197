import React, { useCallback, useEffect, useState } from "react";
import {
  Placeholder,
  useSitecoreContext,
  VisitorIdentification,
} from "@sitecore-jss/sitecore-jss-react";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import deepEqual from "deep-equal";
import Helmet from "react-helmet";

// Using bootstrap is completely optional. It's used here to provide a clean layout for samples,
// without needing extra CSS in the sample app. Remove it in package.json as well if it's removed here.
import "bootstrap/dist/css/bootstrap.css";
import "./assets/app.css";
import "./assets/app.scss";
import "./Layout.scss";
import logo from "./assets/sc_logo.svg";

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

// This is boilerplate navigation for sample purposes. Most apps should throw this away and use their own navigation implementation.
// Most apps may also wish to use GraphQL for their navigation construction; this sample does not simply to support disconnected mode.
let Navigation = ({ t, i18n }) => (
  <div className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom">
    <h5 className="my-0 mr-md-auto font-weight-normal">
      <NavLink to="/" className="text-dark">
        <img src={logo} alt="Sitecore" />
      </NavLink>
    </h5>
    <nav className="my-2 my-md-0 mr-md-3">
      <a
        className="p-2 text-dark"
        href="https://jss.sitecore.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        {t("Documentation")}
      </a>
      <NavLink to="/styleguide" className="p-2 text-dark">
        {t("Styleguide")}
      </NavLink>
      <NavLink to="/graphql" className="p-2 text-dark">
        {t("GraphQL")}
      </NavLink>
    </nav>
  </div>
);

// inject dictionary props (`t`) into navigation so we can translate it
// NOTE: using this is needed instead of using i18next directly to keep
// the component state updated when i18n state (e.g. current language) changes
Navigation = withTranslation()(Navigation);

const Layout = (props) => {
  const REACT_VERSION = React.version;
  console.log(REACT_VERSION);
  const { route } = props;

  const Main = useCallback(() => {
    try {
      return <Placeholder name="jss-main" rendering={route} />;
    } catch (error) {
      console.error(error);
      return <>{error.stack}</>;
    }
  }, [route]);

  const sitecoreContext = useSitecoreContext();
  const ArrowUp = React.memo(() => {
    const [showArrow, setShowArrow] = useState(false);
    const handleNavigation = useCallback((e) => {
      const window = e.currentTarget;
      if (window.scrollY > 420) {
        setShowArrow(true);
      } else {
        setShowArrow(false);
      }
    }, []);
    useEffect(() => {
      if (window !== undefined)
        window.addEventListener("scroll", handleNavigation);

      if (
        route?.fields?.["PageScript"] &&
        route?.fields?.["PageScript"]?.value
      ) {
        injectScriptsFromHTMLString(route?.fields?.["PageScript"]?.value);
      }
    }, []);

    function hardInjectScriptFromStringIntoBody(str) {
      if (typeof document != "undefined") {
        const script = document.createElement("script");
        script.innerHTML = str;

        document.body.appendChild(script);
      }
    }

    function injectScriptsFromHTMLString(str) {
      if (typeof document != "undefined") {
        document.head.insertAdjacentHTML("beforeend", str);
      }
    }

    useEffect(() => {
      let componentsLoaded = false;
      if (typeof window !== "undefined" && typeof document != "undefined") {
        window.addEventListener("componentsLoaded", () => {
          if (componentsLoaded) return;

          componentsLoaded = true;
          document.body.classList.add("components-loaded");
          if ("requestIdleCallback" in window) {
            window.requestIdleCallback(
              () => {
                window.dispatchEvent(new Event("resize"));
                setTimeout(() => {
                  loadCookieLaw(sitecoreContext.sitecoreContext);
                  loadAdditionalScripts(sitecoreContext.sitecoreContext);
                  addSitecoreInfo(sitecoreContext.sitecoreContext);
                }, 2000);
              },
              {
                timeout: 10000,
              }
            );
          } else {
            window.dispatchEvent(new Event("resize"));
            setTimeout(() => {
              loadCookieLaw(sitecoreContext.sitecoreContext);
              loadAdditionalScripts(sitecoreContext.sitecoreContext);
              addSitecoreInfo(sitecoreContext.sitecoreContext);
            }, 2000);
          }
        });
      }
    }, []);
    return (
      <div
        onClick={() => {
          window.scroll(0, 0);
        }}
        className={`scroll-top ${
          showArrow ? "scroll-top_visible" : "scroll-top_invisible"
        }`}
      >
        <button>
          <i className={"icon icon-arrow-up"} aria-hidden={true} />
        </button>
      </div>
    );
  });
  const getTitle = useCallback(() => {
    if (!route?.fields || !route.fields?.pageTitle?.value?.length > 0)
      return "Page";
    if (route.fields.brandTitle?.value?.length > 0) {
      if (route.fields.pageTitle.value === "$name") {
        return route.fields.brandTitle.value;
      }
      return `${route.fields.pageTitle.value} | ${route.fields.brandTitle.value}`;
    } else return route.fields.pageTitle.value;
  }, [route]);

  return (
    <React.Fragment>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <Helmet>
        {sitecoreContext?.sitecoreContext?.ContextExtensions?.favicon
          ?.value && (
          <link
            rel="shortcut icon"
            href={
              sitecoreContext?.sitecoreContext.ContextExtensions.favicon.value
            }
          />
        )}
        <title>{getTitle()}</title>
        {route?.fields?.MetaDescription?.value && (
          <meta
            name="description"
            content={route?.fields?.MetaDescription?.value}
          />
        )}
        {route?.fields?.MetaKeywords?.value && (
          <meta name="keywords" content={route?.fields?.MetaKeywords?.value} />
        )}

        {(Array.isArray(
          sitecoreContext?.sitecoreContext?.AlternateLink?.links?.links
        )
          ? sitecoreContext?.sitecoreContext?.AlternateLink?.links?.links
          : []
        ).map((item, index) => {
          return (
            <link
              key={index}
              rel="alternate"
              hreflang={item.key}
              href={item.value}
            />
          );
        })}
      </Helmet>
      {/*
      VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
      If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
      For XM (CMS-only) apps, this should be removed.

      VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
    */}
      <Placeholder name="jss-header" rendering={route} />
      <div className={"main_wrapper"}>
        <VisitorIdentification />

        {/* <Navigation /> */}

        {/* root placeholder for the app, which we add components to using route data */}
        <div
          className={`fpt-industrial main ${
            route.fields.backgroundColor?.value === "Lightgray"
              ? "main--lightgray"
              : ""
          }`}
        >
          <Main />
        </div>

        <div className="">
          <Placeholder name="jss-footer" rendering={route} />
        </div>
        {<ArrowUp />}
      </div>
    </React.Fragment>
  );
};

const propsAreEqual = (prevProps, nextProps) => {
  if (deepEqual(prevProps.route, nextProps.route)) return true;

  return false;
};

function injectScriptsFromHTMLString(str) {
  if (typeof document != "undefined") {
    const tpl = document.createElement("template");
    tpl.innerHTML = str;
    tpl.content.childNodes.forEach((child) => {
      const node = cloneNode(child);
      document.head.appendChild(node);
    });
  }
}

function loadCookieLaw(context) {
  injectScriptsFromHTMLString(
    context?.ContextExtensions?.onetrustcookie?.value
  );
}

function loadAdditionalScripts(context) {
  injectScriptsFromHTMLString(context?.ContextExtensions?.bodyscript?.value);
  injectScriptsFromHTMLString(
    context?.ContextExtensions?.formOneTrustScript?.value
  );
}

function addSitecoreInfo(context) {
  const sitecoreInfo =
    "<!--\r\nsite:" +
    context?.site?.name +
    "\r\nitemId:" +
    context?.route?.itemId +
    "\r\nlanguage:" +
    context?.language +
    "\r\ndatabase:" +
    context?.route?.databaseName +
    "\r\n-->";
  injectScriptsFromHTMLString(sitecoreInfo);
}

function cloneNode(node) {
  if (node.tagName === "SCRIPT" && typeof document != "undefined") {
    const script = document.createElement("script");
    script.text = node.innerHTML;

    let i = -1,
      attrs = node.attributes,
      attr;
    while (++i < attrs.length) {
      script.setAttribute((attr = attrs[i]).name, attr.value);
    }

    return script;
  } else {
    return node.cloneNode();
  }
}

/**
 * Created before aknowledging that the BE is providing as an HTML snippet to inject.
 */
function manuallyLoadCookieLaw() {
  /* OneTrust Cookies Consent Notice start for eucomcrg04-123710-cd.azurewebsites.net */
  if (typeof document != "undefined") {
    const clScripts = [];
    clScripts.push(document.createElement("script"));
    clScripts.push(document.createElement("script"));
    clScripts.push(document.createElement("script"));
    clScripts[0].src =
      "https://cdn.cookielaw.org/consent/9e19a4e9-f090-4195-921d-c529df46fb50-test/OtAutoBlock.js";
    clScripts[1].src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
    clScripts[1].setAttribute("data-document-language", "true");
    clScripts[1].setAttribute("charset", "UTF-8");
    clScripts[1].setAttribute(
      "data-domain-script",
      "9e19a4e9-f090-4195-921d-c529df46fb50-test"
    );
    clScripts[2].innerHTML = "function OptanonWrapper() {}";

    clScripts.forEach((script) => document.head.appendChild(script));
    /* OneTrust Cookies Consent Notice end for eucomcrg04-123710-cd.azurewebsites.net */
  }
}

/**
 * Created before aknowledging that the BE is providing as an HTML snippet to inject.
 */
function manuallyLoadGoogleTagManager() {
  /* Google Tag Manager */
  if (typeof document != "undefined") {
    const googleTagManagerId = "GTM-PFDK72";
    const tagManagerScript = document.createElement("script");
    tagManagerScript.innerHTML = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', '${googleTagManagerId}');`;
    document.head.appendChild(tagManagerScript);
    /* End Google Tag Manager */
  }
}

export default React.memo(Layout, propsAreEqual);
