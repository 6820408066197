import { RestDictionaryService } from '@sitecore-jss/sitecore-jss-react';
import config from '../temp/config';
import { AxiosDataFetcher, debug } from '@sitecore-jss/sitecore-jss';

export class DictionaryServiceFactory {
  create() {
    const dataFetcher = (url) => {
      const isDev = process.env.NODE_ENV === 'development';

      return new AxiosDataFetcher({
        debugger: debug.dictionary,
        // CORS issue: Sitecore provides 'Access-Control-Allow-Origin' as wildcard '*', so we can't include credentials for the dictionary service
        withCredentials: false,
        rejectUnauthorized: !isDev,
      }).fetch(url);
    };

    return new RestDictionaryService({
      apiHost: config.sitecoreApiHost,
      apiKey: config.sitecoreApiKey,
      siteName: config.jssAppName,
      dataFetcher,
    });
  }
}

export const dictionaryServiceFactory = new DictionaryServiceFactory();
